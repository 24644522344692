.dashboard-component-inner {
  padding: 30px;
  .page-header {
    font-size: 20px;
  }
  .small-box {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: rgba(224, 224, 224, 0.5) 2px 2px 4px 0;
    min-height: 208px;
    padding: 24.5px 15px;
    margin-bottom: 30px;
    .dashboard-box {
      display: block;
      border-bottom: 1px solid rgba(232, 232, 232, 1);
      .box-label {
        font-size: 14px;
        color: rgba(1, 1, 1, 0.85);
        margin-bottom: 0;
        font-weight: 400;
      }
      .box-number {
        font-size: 60px;
        color: rgba(24, 144, 255, 1);
      }
    }
    .icon {
      font-size: 45px;
      position: absolute;
      right: 26px;
      top: 26px;
      color: #fff;
      border-radius: 100%;
      width: 80px;
      height: 80px;
      text-align: center;
      line-height: 80px;
      @media only screen and (max-width: 1025px) {
        display: none;
      }
      &.bg-color-blue {
        background-color: #E0F4FC;
      }
      &.bg-color-green {
        background-color: #EBF5E1;
      }
      &.bg-color-violet {
        background-color: #F0E9F5;
      }
      &.bg-color-pink {
        background-color: #F7E7E6;
      }
    }
    .small-box-footer {
      font-size: 14px;
      color: rgba(1, 1, 1, 0.45);
      padding-top: 20px;
      display: block;
    }
  }
}