//Break point
.xxs-min(@content) {
  @media (max-width: 320px) {
  	@content();
  }
}

.xxs(@content) {
  @media (max-width: 375px) {
  	@content();
  }
}

.xs(@content) {
  @media (max-width: 576px) {
  	@content();
  }
}

.sm(@content) {
  @media (min-width: 576px) {
  	@content();
  }
}

.md(@content) {
  @media (min-width: 768px) {
  	@content();
  }
}

.lg(@content) {
  @media (min-width: 992px) {
  	@content();
  }
}

.xl(@content) {
  @media (min-width: 1200px) {
  	@content();
  }
}

.xxl(@content) {
  @media (min-width: 1440px) {
  	@content();
  }
}
