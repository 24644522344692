.error-page {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  .headline {
    float: left;
    font-size: 100px;
    font-weight: 300;
  }
  .error-content {
    padding-top: 25px;
    margin-left: 190px;
    display: block;
    h3 {
      font-weight: 300;
      font-size: 25px;
    }
  }
}
